const get = <T>(key: string): T | null => {
  const item = window.localStorage.getItem(key);

  if (!item) {
    return null;
  }

  try {
    return JSON.parse(item);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const set = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

const remove = (key: string) => {
  window.localStorage.removeItem(key);
};

const clear = () => {
  window.localStorage.clear();
};

export default () => ({
  get,
  set,
  remove,
  clear,
});
