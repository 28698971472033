import type {
  Customer,
  CustomerAccessToken,
} from "@/graphql/generated/graphql-operations";

interface CustomerStore {
  customer: Customer;
  customerAccessToken: CustomerAccessToken;
}

const createEMRInstance = (url: string, headers = {}) => {
  const { EMR_BASE_URL } = useTheme();
  return axios.create({
    baseURL: `${EMR_BASE_URL}/${url}`,
    headers,
  });
};

export default function useEMRApi() {
  const { get } = useLocalStorage();
  const customer = get<CustomerStore>("customer");
  const customerAccessToken =
    customer?.customerAccessToken?.accessToken ||
    useCustomer()?.customerAccessToken?.accessToken;

  const EMRCustomer = createEMRInstance("CustomerV0");
  const EMRMembership = createEMRInstance("MembershipV0", {
    Authorization: `Token ${customerAccessToken}`,
  });

  return {
    EMRCustomer,
    EMRMembership,
  };
}
